$('.numbers-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    nextArrow: '<button class="icon-right-arrow next"></button>',
    prevArrow: '<button class="icon-left-arrow prev"></button>',
    responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
        }
    ]
})

$('.advantages').slick({
    dots: true,
    arrows: false
})

$('.single-slider').slick({
    nextArrow: '<button class="icon-right-arrow next"></button>',
    prevArrow: '<button class="icon-left-arrow prev"></button>',
})

$('.gallery-slider').slick({
    nextArrow: '<button class="icon-right-arrow next"></button>',
    prevArrow: '<button class="icon-left-arrow prev"></button>',
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnFocus: false
})

