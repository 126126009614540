$(document).on('click', '.accord-header', function(){
    var elem                = $(this),
        content             = elem.next(),
        parent              = elem.parent(),
        siblings            = parent.siblings(),
        siblings_content    = siblings.find('.accord-content')

    parent.toggleClass('active')
    content.stop().slideToggle(300)
    siblings.removeClass('active')
    siblings_content.stop().slideUp(300)
})