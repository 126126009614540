function addNotif(title, text, status){
    var feed = $('.notifications'),
        date = new Date(),
        hours = date.getHours(),
        hours = hours < 10 ? '0' + hours : hours,
        minutes = date.getMinutes(),
        minutes = minutes < 10 ? '0' + minutes : minutes,
        date = hours + ':' + minutes,
        structure = '<div class="notif last"><div class="content">'

    if(status){
        structure += '<div class="notif-title ' + status + '">'
    }else{
        structure += '<div class="notif-title"><span>' + title.charAt(0) + '</span>'
    }

    structure += `<div class="title-inner df aib">` + title + `</div><div class="date">` + date + `</div></div>
        <p>` + text + ` <span class="close-notif">Закрыть</span></p>
    </div></div>`

    feed.append(structure)
    
    var elem = feed.find('.notif.last'),
        height = elem[0].clientHeight

    elem.removeClass('last')
    elem.css({
        'marginBottom': '-' + height + 'px'
    })

    elem.animate({
        marginBottom: '20px'
    }, 400)

    elem.addClass('loaded')

    setTimeout(function(){
        closeNotif(elem)
    }, 4500)
}

function closeNotif(elem){
    var height = elem[0].clientHeight + 20

    elem.animate({
        marginBottom: '-' + height + 'px'
    }, 400)

    elem.addClass('closing')

    setTimeout(function(){
        elem.remove()
    }, 400)
}

$(document).on('click', '.close-notif', function(){
    var elem = $(this).parents('.notif')

    closeNotif(elem)
})

if($('section.dashboard').length){
    
    $('.close-fs-notif').click(function(){
        $('.dashboard-full-screen-notif').stop().fadeOut(300)
    })

}

$(document).on('mouseenter', '.notif-item.new', function(){
    var elem = $(this),
        count = $('#new_notif_count'),
        id = elem.data('id'),
        new_notifs = $('.notif-item.new').length - 1

    if(new_notifs){
        count.text(new_notifs)
    }else{
        count.fadeOut(200)
    }

    elem.removeClass('new')

    sendAjax('viewed_notif', id)
})