function openPopup(popup, video){
    if(typeof(popup) == 'string'){
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeIn(400)
}

function closePopup(popup){
    if(typeof(popup) == 'string'){
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeOut(400)
}

$('.order-btn').click(function(){
    var elem = $(this),
        id = elem.data('id');
    
    if(id){
        var input = $('#p_hotel_number'),
            dropdown = input.next(),
            active = dropdown.find('li[data-id="' + id + '"]');

        dropdown.find('li').removeClass('active');
        active.addClass('active');

        input.attr('data-id', id).val(active.text());

    }
    openPopup('order-popup')
})

$(document).on('click', '.close-popup', function(){
    var elem = $(this),
        popup = elem.parents('.popup'),
        btn = popup.find('.btn')

    closePopup(popup)
    btn.removeAttr('disabled')
})