var min_date = $('#check_in').val();

$('.date-input').datepicker({
    prevText: '<span class="icon-left prev-month"></span>',
    nextText: '<span class="icon-right next-month"></span>',
    dateFormat: "dd.mm.yy",
    monthNames: [ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Новябрь", "Декабрь" ],
    firstDay: 1,
    dayNamesMin: [ "ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ" ],
    selectOtherMonths: true,
    showOtherMonths: true,
    minDate: min_date
});

$('#fs_send').click(function(e){
    var btn = $(this),
        form = $('.check-place'),
        inputs = form.find('.required');

    e.preventDefault();
    inputs.each(inputCheck);
    
    if(!form.find('.error').length){
        var phone = $('#fs_phone').val(),
            number = $('#hotel_number').val(),
            check_in = $('#check_in').val(),
            message = '',
            check_out = $('#check_out').val(),
            results = {
                phone,
                number,
                message,
                check_in,
                check_out
            };

        btn.attr('disabled', true);

        sendAjax('add_order', results, function(){
            btn.attr('disabled', false)
            $('.phone-mask').each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
        },function(res){
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }
})

$('#order_send').click(function(e){
    var btn = $(this),
        form = $('#form'),
        popup = $('.order-popup'),
        inputs = form.find('.required');

    e.preventDefault();
    inputs.each(inputCheck);
    
    if(!form.find('.error').length){
        var phone = $('#p_phone').val(),
            number = $('#p_hotel_number').val(),
            check_in = $('#p_check_in').val(),
            check_out = $('#p_check_out').val(),
            message = '',
            results = {
                phone,
                number,
                check_in,
                check_out,
                message
            };

        btn.attr('disabled', true)

        sendAjax('add_order', results, function(){
            btn.attr('disabled', false)
            $('.phone-mask').each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
            closePopup(popup);
        },function(res){
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }
})

$('#c_order_send').click(function(e){
    var btn = $(this),
        form = $('#с_form'),
        inputs = form.find('.required');

    e.preventDefault();
    inputs.each(inputCheck);
    
    if(!form.find('.error').length){
        var phone = $('#c_phone').val(),
            number = '',
            check_in = '',
            message = $('#c_message').val(),
            check_out = '',
            results = {
                phone,
                number,
                message,
                check_in,
                check_out
            };

        btn.attr('disabled', true)

        sendAjax('add_order', results, function(){
            btn.attr('disabled', false)
            inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
        },function(res){
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }
})